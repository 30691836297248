import {Header} from '../Header';
import {Main} from '../Main';
import {Footer} from '../Footer';

import './Container.scss';

interface IContainerProps {
  children: any;
}

export function Container(props: IContainerProps) {
  const {children} = props;

  return (
    <div className={`layout page-enter`}>
      <Header />
      <Main>{children}</Main>
      <Footer />
      <div className="espa">
        <a href="https://topos-grigoriadou.gr/espa/afisa.pdf" title="ΕΠΑνΕΚ" target="_blank" rel="noreferrer">
          <img src="https://topos-grigoriadou.gr/espa/espa-el.jpg" alt="ΕΠΑνΕΚ" />
        </a>
      </div>
    </div>
  );
}
