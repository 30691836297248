import React from "react";
import useAxios from "axios-hooks";
import { API_URL, API_NAME, get_collections, get_singletons } from "../../../config/config.json";

import "./Footer.scss";
import UseLink from "../../../hooks/UseLink";

export function Footer() {
  const [{ data, loading }] = useAxios(
`${API_URL}/${API_NAME}/${get_collections}/genericpages?rspc=1`
  )
  const [{ data: settingsData, loading: settingsLoading }] = useAxios(
    `${API_URL}/${API_NAME}/${get_singletons}/ssettings?rspc=1`
      )
  
  return (
    <footer>
       {data && settingsData &&(
        <div className="container">
          <div className="footer-top">
          <div className="col-1">
            <div className="site-title">{settingsData.siteTitle}</div>
            <div className="site-subTitle">{settingsData.siteSubtitle}</div>
          </div>
          <div className="col-2">
            <div className="col-title">Επικοινωνία <span className="footer-arrow">--&gt;</span></div>
            <div className="site-phone"><span>Τ</span><a href={`tel:${settingsData.phone}`} title={`Τηλέφωνο: ${settingsData.phone}`}>{settingsData.phone}</a></div>
            <div className="site-email"><span>E1</span><a href={`mailto:${settingsData.email}`} title={`Email 1: ${settingsData.email}`}>{settingsData.email}</a></div>
            <div className="site-email"><span>E2</span><a href={`mailto:${settingsData.email2}`} title={`Email 2: ${settingsData.email2}`}>{settingsData.email2}</a></div>
          </div>
          <div className="col-3">
            <a href={settingsData.googleMapUrl} title="Βρείτε μας στον χάρτη" target="_blank" rel="noreferrer">
            <div className="col-title">Βρείτε μας στον χάρτη <span className="footer-arrow">--&gt;</span></div>
            <div className="site-address">{settingsData.address}, ΤΚ {settingsData.zipCode}</div>
            <div className="site-city">{settingsData.city}</div>
            </a>
          </div>
          <div className="col-4">
          <div className="col-title"><span className="footer-arrow">--&gt;</span></div>

            <div className="legal">
            { data && (
              <div className="footer-nav">
              {data.entries.map((footerPage: any, i: number) => (
                <UseLink key={i} url={`/pages/${footerPage.pageSlug}`} name={`Ισμήνη Γρηγοριάδου - ${footerPage.pageTitle}`}>{footerPage.pageTitle}</UseLink>
              ))}
              </div>
            )}
              </div>
              <div className="design">Σχεδιασμός <a href="https://www.behance.net/a_andia" title="Σχεδιασμός ΑΑ" target="_blank" rel="noreferrer">ΑΑ</a></div>
          </div>
          </div>
        </div>
      )}
    </footer>
  );
}
