import { Link, useHistory } from "react-router-dom";

interface IUseLinkProps {
    url: string; 
    className?: string;
    name?: string;
    children: any;
}

export default function UseLink(props: IUseLinkProps) {
    const { url, className, children, name } = props;
    const history = useHistory();

    const delayRedirect = (e, target) => {
        document.body.classList.remove('page-enter');
        document.body.classList.add('page-exit');
        setTimeout( () => {
            history.push(target)
            document.body.classList.remove('page-exit');
        },700)
        setTimeout( () => {
            document.body.classList.add('page-enter');
        },700)
        e.preventDefault();
    }

    return (
        <>
        { url.includes("mailto:") || url.includes("href://") 
        ? (<a href={url} target="_blank" className={className} title={`${name}`} rel="noreferrer">{children}</a>)
        : (<Link to={url} className={className} title={`${name}`}  onClick={ (e) => { delayRedirect(e, url) } }>
            {children}
        </Link>)
        }
        </>
        );
}