import {useEffect, useState} from 'react';
import UseLink from '../../../hooks/UseLink';
import useAxios from 'axios-hooks';
import {API_URL, API_NAME, get_collections} from '../../../config/config.json';
import Nav from './Nav';

import './Header.scss';

export function Header() {
  const [isCollapsed, setIsCollapsed] = useState('');
  const [mobileNav, setMobileNav] = useState(false);

  const [{data, loading}] = useAxios(`${API_URL}/${API_NAME}/${get_collections}/navigation?rspc=1`);

  useEffect(() => {
    window.onscroll = function (i: any) {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos === 0) {
        setIsCollapsed('');
      } else {
        setIsCollapsed('is-collapsed');
      }
    };
  }, []);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        if (window.innerWidth > 1023) {
          setMobileNav(false);
        }
      },
      false,
    );
  }, []);

  useEffect(() => {
    if (document.body.classList.contains('page-exit')) {
      setMobileNav(false);
      alert('test');
    }
  }, []);

  useEffect(() => {
    if (mobileNav) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [mobileNav]);

  var navElements = document.getElementsByClassName('nav-link');
  for (var i = 0; i < navElements.length; i++) {
    navElements[i].addEventListener('click', () => setMobileNav(false), false);
  }

  return (
    <header className={`${isCollapsed} ${mobileNav ? 'mobileIsOpened' : ''}`}>
      <div className="container">
        <div className="brand">
          <h1>Ισμήνη Γρηγοριάδου - Τοπογράφος</h1>
          <UseLink url={`/`} name="Ισμήνη Γρηγοριάδου - Τοπογράφος">
            <svg>
              <use xlinkHref="#logo" />
            </svg>
          </UseLink>
        </div>
        {!loading && data && <Nav data={data.entries} />}
        <div className={`mobile-nav`}>
          <div className="nav-icon" onClick={() => setMobileNav(!mobileNav)}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {!loading && data && (
          <div className="mobile-menu">
            <Nav data={data.entries} />
          </div>
        )}
      </div>
    </header>
  );
}
