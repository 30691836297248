import React from "react";
import UseLink from '../../../hooks/UseLink'
interface NavData {
  data: any;
}

export default function Nav(props: NavData) {
  const { data } = props;
  

  return (
    <nav>
      <UseLink url={`/`} name={`Ισμήνη Γρηγοριάδου`} className="nav-link hide-desktop">
          ΑΡΧΙΚΗ
          </UseLink>
      {data.map((item: any, i: number) => (
        <UseLink key={i} url={`/${item.menuLink}`} name={`Ισμήνη Γρηγοριάδου - ${item.menuTitle}`} className="nav-link">
          {item.menuTitle}
          </UseLink>
      ))}
    </nav>
  );
}
